module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"হাতেকলমে জাভাস্ক্রিপ্ট","short_name":"জাভাস্ক্রিপ্ট","start_url":"/","background_color":"#f0db4f","theme_color":"#323330","display":"fullscreen","icon":"src/assets/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#f0db4f","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
